import React from "react";
import { Box, Typography, Container, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { t } from "@lingui/macro";
import Link from "../link";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
  },
  aboutUsContainer: {
    flexGrow: 1,
    padding: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const AboutUsComponent = ({ title, body, partnerInformationStatus }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Container
        className={classes.aboutUsContainer}
        style={{ justifyContent: partnerInformationStatus === "loading" ? "center" : "flex-start" }}
      >
        {partnerInformationStatus === "loading" ? (
          <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" gridGap={32}>
            <Typography variant="h2" color="primary" gutterBottom>
              {title || t`No content available`}
            </Typography>

            <Box display="flex" flexDirection="column" gridGap={8}>
              {body ? (
                body.split("\n").map((paragraph, index) => (
                  <Typography key={index} variant="h4" color="secondary" paragraph>
                    {paragraph}
                  </Typography>
                ))
              ) : (
                <Typography variant="h4" color="secondary" paragraph>
                  {t`Contact the administrator to configure content on this page.`}
                </Typography>
              )}
            </Box>

            <Link href="/home">
              <Button variant="contained" color="primary" size="large" sx={{ mt: 2 }}>
                {t`Return Home`}
              </Button>
            </Link>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default AboutUsComponent;
