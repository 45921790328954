import React, { useState, useEffect } from "react";
import AboutUsComponent from "../../components/about-us";
import { getPartnerInformation as getPartnerInformationApi } from "../../api/v2";

const AboutUsContainer = () => {
  const [partnerInformation, setPartnerInformation] = useState({});
  const [partnerInformationStatus, setPartnerInformationStatus] = useState("initial");

  const getPartnerInformation = async () => {
    setPartnerInformationStatus("loading");
    const res = await getPartnerInformationApi("property", "role:about");
    if (res.status === 200) {
      setPartnerInformationStatus("success");
      const title = res.data?.payload?.find((item) => item.name === "about_title")?.value;
      const body = res.data?.payload?.find((item) => item.name === "about_body")?.value;
      setPartnerInformation({ title, body });
    } else {
      setPartnerInformationStatus("error");
    }
  };

  useEffect(() => {
    getPartnerInformation();
  }, []);

  return (
    <AboutUsComponent
      title={partnerInformation.title}
      body={partnerInformation.body}
      partnerInformationStatus={partnerInformationStatus}
    />
  );
};

export default AboutUsContainer;
